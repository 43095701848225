import Column from 'bloko/blocks/column';

import AdsExternalBanner from 'src/components/Banner/AdsExternalBanner';
import { useSelector } from 'src/hooks/useSelector';

const VacancyFavoriteSidebar = () => {
    const rightBanners = useSelector(({ banners }) => banners[`right-column`]);
    if (!rightBanners?.length) {
        return null;
    }

    return (
        <Column xs={'4'} m={'4'} l={'5'}>
            <div className={'center-banner-xs'} data-banner-id={rightBanners[0].id}>
                {rightBanners && rightBanners.map((banner) => <AdsExternalBanner key={banner.id} {...banner} />)}
            </div>
        </Column>
    );
};

export default VacancyFavoriteSidebar;
